import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/possessions';

export default {
  listPossessions(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  delete(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
  createPossession(possession) {
    return axios.post(`${baseUrl}`, possession);
  },
  updatePossession(id, possession) {
    return axios.put(`${baseUrl}/${id}`, possession);
  },
  async uploadImage(id, image) {
    const formData = new FormData();
    formData.append('file', image);
    try {
      const response = await axios.put(`${baseUrl}/${id}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getImage(possessionId, imageId) {
    return axios.get(`${baseUrl}/${possessionId}/images/${imageId}`, {
      responseType: 'arraybuffer'
    });
  },
  deleteImage(possessionId, imageId) {
    return axios.delete(`${baseUrl}/${possessionId}/images/${imageId}`)
  },
};
