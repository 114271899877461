<style lang="scss">
</style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updatePossession)">
        <section class="mb-2">
          <div>
            <label>Title *</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true, min: 2 }"
              name="Title"
            >
              <b-form-input
                v-model="possession.title"
                :state="getValidationState(validationContext)"
                class="mb-1"
                placeholder="Enter title"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Description</label>
            <b-form-input v-model="possession.description" placeholder="Enter description" />
          </div>

          <div class="mb-1">
            <label>Colour</label>
            <b-form-input v-model="possession.colour" placeholder="Enter colour" />
          </div>

          <div class="mb-1">
            <label>Origin</label>
            <b-form-input v-model="possession.origin" placeholder="Enter origin"/>
          </div>

          <div class="mb-1">
            <label>Cost</label>
            <b-form-input
              v-model="possession.cost_of_item"
              type="number"
              step="0.01"
              placeholder="Enter cost of item"
            />
          </div>

          <div class="mb-1">
            <label>Is Prohibited</label>
            <b-form-select v-model="possession.is_prohibited">
              <b-form-select-option selected value="false">No</b-form-select-option>
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Is Risk</label>
            <b-form-select v-model="possession.is_risk">
              <b-form-select-option selected value="false">No</b-form-select-option>
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Owner</label>
            <CAutoComplete
              :value="initialOwnerVal"
              :items="owners"
              :get-label="getOwnerLabel"
              :component-item="ownerTemplate"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for an owner..."
              @update-items="searchOwners"
              @input="selectOwner"
            />
          </div>

          <div class="mb-2">
            <label>Category *</label>
            <CAutoComplete
              :value="initialCategoryVal"
              :items="categories"
              :component-item="nameTemplate"
              :get-label="getCategoryLabel"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for a category..."
              @update-items="searchCategories"
              @input="selectCategory"
            />
          </div>

          <div class="mb-1">
            <label>Sub Category *</label>
            <CAutoComplete
              :value="initialSubCategoryVal"
              :disabled="!possession.item_category_id"
              :items="subCategories"
              :component-item="nameTemplate"
              :get-label="getCategoryLabel"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for a subcategory..."
              @update-items="searchSubCategories"
              @input="selectSubCategory"
            />
          </div>

          <div class="mb-1">
            <label>Location *</label>
            <CAutoComplete
              :value="initialLocationVal"
              :items="locations"
              :component-item="nameTemplate"
              :get-label="getLocationLabel"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for a location..."
              @update-items="searchLocations"
              @input="selectLocation"
            />
          </div>

        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button
            class="mr-2"
            @click="$emit('close', true)"
          >Cancel</b-button>
          <b-button
            type="submit"
            variant="primary"
            :disabled="!valid()"
          >Update possession</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import CategoriesService from '@/services/PossessionCategoriesService';
import PossessionsService from "@/services/PossessionsService";
import LocationsService from "@/services/LocationsService";
import OwnersService from "@/services/OwnersService";
import OwnerTemplate from "@/components/input/CAutoCompleteTemplates/OwnerTemplate.vue";
import CAutoComplete from "@/components/input/CAutocomplete.vue";
import ItemNameTemplate from "@/components/input/CAutoCompleteTemplates/ItemNameTemplate.vue";
import helperService from "@/services/HelperService";

export default {
  components: {CAutoComplete},
  props: {
    targetPossession: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      ownerTemplate: OwnerTemplate,
      nameTemplate: ItemNameTemplate,
      categorySearch: '',
      categories: [],
      initialCategoryVal: null,
      subcategorySearch: '',
      subCategories: [],
      initialSubCategoryVal: null,
      locationSearch: '',
      locations: [],
      initialLocationVal: null,
      possession: {...this.targetPossession},
      ownerSearch: '',
      owners: [],
      initialOwnerVal: null,
    };
  },
  mounted() {
    // There is a chance when we load Categories, Location and owner
    // That it is not included in the list brought back.
    // So we need to inject what is on the possession if it is not already
    // in the list
    this.getCategories().then(() => {
      // Check if categories does not include the initial value
      if(!this.categories.some(cat => cat.id === this.targetPossession.item_category_id)) {
        // Inject category into the list
        this.categories.unshift(this.targetPossession.category)
      }

      // Set initial value
      const initialVal = this.categories.find(cat => cat.id === this.targetPossession.item_category_id)
      this.initialCategoryVal = {...initialVal}
    })
    this.getSubCategories().then(() => {
      // Check if sub categories does not include the initial value
      if(!this.subCategories.some(cat => cat.id === this.targetPossession.item_sub_category_id)) {
        // Inject sub category into the list
        this.subCategories.unshift(this.targetPossession.subcategory)
      }

      // Set initial value
      const initialVal = this.subCategories.find(cat => cat.id === this.targetPossession.item_sub_category_id)
      this.initialSubCategoryVal = {...initialVal}
    })
    this.getLocations().then(() => {
      // Check if locations does not include the initial value
      if(!this.locations.some(loc => loc.id === this.targetPossession.location_id)) {
        // Inject location into the list
        this.locations.unshift(this.targetPossession.location)
      }

      // Set initial value
      const initialVal = this.locations.find(loc => loc.id === this.targetPossession.location_id)
      this.initialLocationVal = {...initialVal}
    })
    this.getOwners().then(() => {
      // Check if owners does not include the initial value
      if(!this.owners.some(owner => owner.uid === this.targetPossession.owner_uid)) {
        // Inject owner into the list
        this.owners.unshift(this.targetPossession.owner)
      }

      // Set initial value
      const initialVal = this.owners.find(owner => owner.uid === this.targetPossession.owner_uid)
      this.initialOwnerVal = {...initialVal}
    })
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    valid() {
      if(this.possession.title.length <= 0) {
        return false
      }
      if(!this.possession.item_category_id) {
        return false
      }
      if(!this.possession.item_sub_category_id) {
        return false
      }
      if(!this.possession.location_id) {
        return false
      }

      return true
    },
    async updatePossession() {
      try {
        // Format data
        this.possession.title = this.possession.title.trim();
        this.possession.cost_of_item = parseFloat(this.possession.cost_of_item)
        this.possession.is_risk = this.possession.is_risk === 'true';
        this.possession.is_prohibited = this.possession.is_prohibited === 'true';

        // If max_items is empty, set it to null
        if (!this.possession.max_items) {
          this.possession.max_items = null;
        }

        await PossessionsService.updatePossession(this.possession.id, this.possession)

        this.$emit('refresh')
        this.$emit('close', true);
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not update possession, please refresh and try again')
      }
    },
    getLocationLabel(loc) {
      if(!loc) {
        return ""
      }

      return loc.name
    },
    searchLocations(input) {
      this.locationSearch = input
      this.getLocations()
    },
    selectLocation(loc) {
      this.possession.location_id = loc ? loc.id : ""
    },
    async getLocations() {
      try {
        const res = await LocationsService.getLocations({
          search: this.locationSearch,
          page_size: 25,
        })
        this.locations = res.data.data;
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get locations, please refresh and try again')
      }
    },
    getCategoryLabel(cat) {
      if(!cat) {
        return ""
      }
      return cat.name
    },
    searchCategories(input) {
      this.categorySearch = input
      this.getCategories()
    },
    selectCategory(category) {
      this.possession.item_category_id = category ? category.id : ""
      this.getSubCategories()
    },
    async getCategories() {
      try {
        const res = await CategoriesService.listCategories({
          search: this.categorySearch,
          parent_id: '0',
          page_size: 25,
        })
        this.categories = res.data.data;
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get categories, please refresh and try again')
      }
    },
    searchSubCategories(input) {
      this.subcategorySearch = input
      this.getSubCategories()
    },
    selectSubCategory(category) {
      this.possession.item_sub_category_id = category ? category.id : ""
    },
    async getSubCategories() {
      try {
        const res = await CategoriesService.listCategories({
          search: this.subcategorySearch,
          parent_id: this.possession.item_category_id,
          page_size: 25,
        })
        this.subCategories = res.data.data;
      } catch(err) {
        const res = err.response
        let errorText = 'Could not get subcategories, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    getOwnerLabel (owner) {
      if (owner === undefined) {
        return ""
      }
      return owner.identifier
    },
    selectOwner(owner) {
      this.possession.owner_uid = owner ? owner.uid : ''
    },
    searchOwners(input) {
      this.ownerSearch = input;
      this.getOwners();
    },
    async getOwners() {
      try {
        const res = await OwnersService.getOwners({
          search: this.ownerSearch,
          page_size: 25,
        });

        this.owners = res.data.data;
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get owners, please refresh and try again');
      }
    },
  },
};
</script>
